import React from 'react'

// Components
import Layout from '../../components/layout.js'
import SEO from '../../components/seo.js'

// SVGs

export default () => (
    <Layout className="project project__qq-form">
        <SEO title="Qualifying Questions" />
        <section className="hero padding-y-20 position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="margin-bottom-4">
                            <span className="highlight__brand-orange">
                                Qualifying Questons
                            </span>
                        </h1>
                        <h3>
                            <span className="highlight__brand-orange">
                                Toast Inc.
                            </span>
                        </h3>
                    </div>
                </div>
            </div>
            <img
                className="hero-img"
                src="https://d2jotzgct8q460.cloudfront.net/imgs/projects/qq-form/QQ-Hero-1.jpg"
                alt="Qualifying Questions Hero"
            />
        </section>
        <section className="padding-y-10 bg-orange-1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 align-self-center">
                        <div className="side-by-side-divider">
                            <div className="left-content margin-bottom-3">
                                <h3 className="margin-bottom-3">
                                    I developed a form building application that
                                    dynamically routes users depending on how
                                    they respond to questions and qualifies them
                                    for a phone call with a sales
                                    respresentative.
                                </h3>
                                <p>
                                    Qualifying questions is a React JS form
                                    builder that takes a JSON feed of questions
                                    and builds a dynamic form. It is integrated
                                    with salesforce to provide Sales
                                    representatives with an aggrregated view of
                                    information about the potential lead.
                                </p>
                            </div>
                            <div className="divider margin-x-10"></div>
                            <div className="right-content">
                                <div className="margin-bottom-3">
                                    <h4>Timeline</h4>
                                    <p>3 Week Build</p>
                                </div>
                                <div className="margin-bottom-3">
                                    <h4 className="">My Role</h4>
                                    <p>Lead Front End Engineer</p>
                                </div>
                                <div className="margin-bottom-3">
                                    <h4 className="margin-bottom-1">
                                        <b>Tools</b>
                                    </h4>
                                    <div className="tag-container">
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                React JS
                                            </div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Hubspot JS API
                                            </div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Marketo JS API
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <h4 className="">
                                        <b>Themes</b>
                                    </h4>
                                    <p>Dynamic Routing | User-Experience</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-10 bg-grey-1">
            <div className="container">
                <div className="row margin-bottom-5">
                    <div className="col-md-12">
                        <h2 className="margin-bottom-3">The Problem</h2>
                        <p>
                            Traditionally to purchase Toast, a user would
                            request a demo on the Toast website and a sales
                            associate would call them to qualify them for Toast
                            products. During these phone calls, the sale
                            associate would ask a number of questions about the
                            role of the individual (Reastuarant Owner, Manager,
                            Wait Staff, etc.), the type of restaurant the
                            indvidual owns or works for, and the Toast products
                            the individual is interested in. This task was time
                            consuming and created situations where the sales
                            associate would have to probe the individual for the
                            necessary information about their restaurant.
                        </p>
                    </div>
                </div>
                <div className="row margin-bottom-5">
                    <div className="col-md-8">
                        <h2 className="margin-bottom-3">The Solution</h2>
                        <p>
                            Create a streamlined process for gathering
                            information about a user interested in
                            demoing/purchasing Toast. After a user requests a
                            demo of Toast, prompt them to complete a set of
                            qualifying questions asking them about their
                            restaurant. Additionally, once the set of qualifying
                            questions is completed, allow the user to shcedule a
                            time on a sales associates calendar to book their
                            demo.
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h2 className="margin-bottom-3">Goals</h2>
                        <ul className="inline-padding-none">
                            <li className="margin-bottom-2">
                                <p>
                                    Build it to be dynamic allowing several
                                    different versions
                                </p>
                            </li>
                            <li className="margin-bottom-2">
                                <p>
                                    Effectively track the compeletion and drop
                                    off rates
                                </p>
                            </li>
                            <li className="margin-bottom-2">
                                <p>
                                    Increase Marketing Qualified Lead (MQL) to
                                    Salesforce Opportunity (Opp)
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="margin-bottom-3">My Role</h2>
                        <p>
                            I was the lead engineer working on this application.
                            I worked with our sales enablement team and design
                            team to determine the set of requirements including
                            the questions they wanted on each version of the
                            form and how to route the user effectvely to the
                            correct sales respresentative. In addition, I
                            developed Qualifying Questions from the ground up
                            using React JS.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-10 overflow-hidden">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 margin-bottom-5">
                        <h2 className="margin-bottom-3">Development Process</h2>
                        <h3 className="">Coming Soon!</h3>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)
